<template>
    <div class="alert-area">
        <transition name="fade" mode="out-in">
            <div :class="alert.type" class="alert text-center" v-if='alert.type'>
                {{ alert.message }}
                <span class="cursor-pointer alert-dismiss unselectable"
                    @click='clear'>
                    &times;
                </span>
            </div>
        </transition>
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
    methods: {
        ...mapActions('alert', ['clear']),
    },
    computed: {
        ...mapState({
            alert: state => state.alert
        })
    }
}
</script>

<style lang="scss" scoped>
    @import '../assets/_variables.scss';

    .alert-area
    {
        position: absolute;
        top: 15px;
        left: 0px;
        right: 0px;
        z-index: 1;
    }

    .alert
    {
        color: rgba(255, 255, 255, 0.75);
        max-width: 500px;
        margin: 0 auto;
    }

    .error
    {
        background-color: $danger;
    }

    .info
    {
        background-color: $info;
    }

    .warning
    {
        background-color: $warning;
    }

    .success
    {
        background-color: $success;
    }

    .fade-enter-active, .fade-leave-active {
        transition: opacity 0.25s ease-out;
    }

    .fade-enter, .fade-leave-to {
        opacity: 0;
    }

    .alert-dismiss {
        font-size: 1.75rem;
        position: absolute;
        top: 2px;
        margin: 0px 10px;
        opacity: 0.85;
    }

    .hidden
    {
        opacity: 0;
    }
</style>