<template>
    <div class="container">
        <nav class="navbar navbar-expand-lg px-0 py-2 mb-4">
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#nav-collapse" aria-controls="nav-collapse">
                <i class="fas fa-bars"></i>
            </button>
            <div class="collapse navbar-collapse" id="nav-collapse">
                <router-link class="navbar-brand"  to='/'>
                    <img src="../../assets/fs_badge.png" class="rounded" width="35" height="35" alt="">
                    FSOFT
                </router-link>
                <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
                    <li class="nav-item">
                        <router-link class="nav-link" to='/dashboard' :class="this.$router.currentRoute.name == 'dashboard' ? 'active' : ''">
                            Irányítópult
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" to='/admin' :class="this.$router.currentRoute.name == 'admin' ? 'active' : ''" v-if="isAdmin">
                            Admin
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <div class="nav-link">
                           Üdv, {{name}}!
                        </div>
                    </li>
                    <li class="nav-item">
                        <a href='#' class="nav-link" @click="logout">
                            Kijelentkezés
                        </a>
                    </li>
                </ul>
            </div>
        </nav>
        <slot></slot>
    </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
export default {
    methods: {
        ...mapActions('account', ['logout'])
    },
    computed: {
        ...mapGetters('account', ['name', 'isAdmin'])
    }
}
</script>

<style lang="scss" scoped>

</style>