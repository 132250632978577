<template>
    <div>
        Work in progess
    </div>
</template>

<script>
import DashboardLayout from '@/components/layouts/Dashboard'
export default {
    name: 'Dashboard',
    created() {
        this.$emit('update:layout', DashboardLayout)
    }
}
</script>

<style>

</style>
