<template>
    <div class="wrapper">
        <input 
            :type="type" 
            :name="name" 
            :id="name" 
            required 
            :value="value"
            v-on:input="$emit('input', $event.target.value)">
        <span :data-placeholder="placeholder"></span>
    </div>
</template>

<script>
export default {
    name: 'login-input',
    props: [
        'type', 'name', 'placeholder', 'value'
    ],

}
</script>

<style lang="scss" scoped>
    @import '../../assets/_variables.scss';
    .wrapper
    {
        border-bottom: 2px solid #f1f1f141;
        margin: 30px 0px;
        position: relative;
        z-index: 1;
    }

    .wrapper input
    {
        font-size: 17px;
        color: #f1f1f1;
        border: none;
        width: 100%;
        outline: none;
        background: transparent;
        padding: 0px 5px;
        height: 40px;
    }

    .wrapper input:invalid
    {
        outline: none;
        border: none;
        box-shadow: none;
    }

    .wrapper span::before
    {
        content: attr(data-placeholder);
        color: white;
        opacity: 0.8;
        position: absolute;
        top: 50%;
        left: 5px;
        transform: translateY(-50%);
        z-index: -1;
        transition: 300ms;
    }

    .wrapper span::after
    {
        content: '';
        position: absolute;
        width: 0px;
        height: 2px;
        background: linear-gradient(120deg,rgb(23, 180, 253), rgb(32, 91, 255));
        bottom: -2px;
        transition: 500ms;
        left: 0px;
    }

    .wrapper input:focus + span::before, .wrapper input:valid + span::before
    {
        top: -5px;
    }

    .wrapper input:focus + span::after, .wrapper input:valid + span::after
    {
        width: 100%;
    }
</style>