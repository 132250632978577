<template>
    <div>
        <p>
            Aláírások 
            <datepicker wrapper-class="d-inline mx-2" v-model="dateFrom" :language="hu" :monday-first="true" @input="fetchCompanies({from: dateFrom, to: dateTo})" /> 
            és 
            <datepicker wrapper-class="d-inline mx-2" v-model="dateTo" :language="hu" :monday-first="true" @input="fetchCompanies({from: dateFrom, to: dateTo})" /> 
            között.
        </p>
        
        <table class="table table-sm">
            <thead>
                <tr>
                    <th class="col-5">
                        Ügyfél
                    </th>
                    <th class="col-3">
                        Ár (Forint/Számla)
                    </th>
                    <th class="col-2">
                        Aláírások száma
                    </th>
                    <th class="col-2">
                        Összesen
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="company in companies" :key="company.id">
                    <td>
                        {{ company.name }}
                    </td>
                    <td>
                        <input type="text" name="companyPrice" v-model="company.price" @keyup="updateComp(company)" class="form-control">
                    </td>
                    <td>
                        {{ company.signRequests.length }}
                    </td>
                    <td>
                        {{ company.price * company.signRequests.length }},- Ft
                    </td>
                </tr>
                <tr class="summary-top">
                    <td>Összesen</td>
                    <td></td>
                    <td> {{ companies.map(x => x.signRequests.length).reduce((a,b) => a+b, 0) }}</td>
                    <td> {{ companies.map(x => x.signRequests.length * x.price).reduce((a,b) => a+b, 0) }},- Ft </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import DashboardLayout from '@/components/layouts/Dashboard'
import Datepicker from 'vuejs-datepicker'
import { hu } from "vuejs-datepicker/dist/locale";
import { mapActions, mapGetters } from "vuex";
export default {
    name: 'Admin',
    methods: {
        ...mapActions('company', ['fetchCompanies', 'updateCompany']),
        updateComp(company) {
            this.skipUpdate = true;
            this.updateCompany(company);
        }
    },
    async created() {
        this.syncHandler = setInterval(async () => {
            if(!this.skipUpdate)
            {
                await this.fetchCompanies({from: this.dateFrom, to: this.dateTo})
            }
            else
            {
                this.skipUpdate = false
            }
        }, 5000);
        await this.fetchCompanies({from: null, to: null});
        this.$emit('update:layout', DashboardLayout);
    },
    computed: {
        ...mapGetters('company', ['companies']),
    },
    components: {
        Datepicker
    },
    data() {
        let today = new Date();
        let dateFrom = new Date(
            today.getFullYear(),
            today.getMonth(),
            1
        );

        let dateTo = new Date(
            today.getFullYear(),
            today.getMonth() + 1,
            0
        );
        return {
            dateFrom,
            dateTo,
            test: '',
            hu: hu,
            skipUpdate: false,
            exists: false,
            syncHandler: null
        }
    },
    destroyed() {
        clearInterval(this.syncHandler);
    }
}
</script>
    
<style lang="scss">
    @import '../../assets/_variables.scss';

    .vdp-datepicker * {
        display: inline-block;
    }
    .summary-top
    {
        border-top: 2px solid $primary;
    }
</style>
