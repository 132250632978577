<template>
    <component :is="layout">
        <Alert />
        <router-view :layout.sync="layout" />
    </component>
</template>

<script>
import Alert from "./components/Alert";
export default {
    name: 'App',
    data() {
        return {
            layout: 'div'
        }
    },
    components: {
        Alert
    }
}
</script>