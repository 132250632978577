<template>
    <div class="mx-auto login-wrapper">
        <div class="login-form rounded">
            <h1 class="text-center pb-4">Bejelentkezés</h1>
            <LoginInput name="username" type="text" v-model="username" placeholder="Felhasználónév" />
            <LoginInput name="password" type="password" v-model="password" placeholder="Jelszó" />
            <div class="text-center">
                <button class="btn btn-login" @click="submit">Bejelentkezés</button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import PublicLayout from '@/components/layouts/Public'
import LoginInput from '@/components/controls/LoginInput.vue'
export default {
    name: 'Login',
    created() {
        this.$emit('update:layout', PublicLayout);
        // this.logout();
    },
    components: {
        LoginInput
    },
    data() {
        return {
            username: '',
            password: ''
        }
    },
    methods: {
        ...mapActions('account', ['login', 'logout']),
        submit()
        {
            if(this.username && this.password)
            {
                this.login(this);
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    @import '../../assets/_variables.scss';
    .login-form
    {
        margin: 0 auto;
        max-width: 360px;
        height: 450px;
        padding: 40px 40px;
        background: $primary;
        color: #f1f1f1;
    }

    .btn-login
    {
        margin-top: 25px;
        padding: 15px 60px;
        color: white;
        background-color: rgba(255, 255, 255, 0.13);
    }

    .btn-login:hover
    {
        color: white;
        background-color: rgba(255, 255, 255, 0.25);
    }
</style>
