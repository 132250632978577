<template>
    <div class="container">
        <nav class="navbar navbar-expand-lg px-0 py-2 mb-4">
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#nav-collapse" aria-controls="nav-collapse">
                <i class="fas fa-bars"></i>
            </button>
            <div class="collapse navbar-collapse" id="nav-collapse">
                <router-link class="navbar-brand"  to='/'>
                    <img src="../../assets/fs_badge.png" class="rounded" width="35" height="35" alt="">
                    FSOFT
                </router-link>
                <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
                    <li class="nav-item">
                        <router-link class="nav-link" to='/' :class="this.$router.currentRoute.name == 'home' ? 'active' : ''">
                            Főoldal
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" to='/contact' :class="this.$router.currentRoute.name == 'contact' ? 'active' : ''">
                            Kapcsolat
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" to='/login' :class="this.$router.currentRoute.name == 'login' ? 'active' : ''">
                            Bejelentkezés
                        </router-link>
                    </li>
                </ul>
            </div>
            
        </nav>
        <slot />
    </div>
</template>

<script>
export default {
    computed: {
        route: () => this.$router.name
    }
}
</script>

<style lang="scss" scoped>
   
</style>
