import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/components/views/Home'
import Contact from '@/components/views/Contact'
import Login from '@/components/views/Login'

import Dashboard from '@/components/views/Dashboard';
import Admin from '@/components/views/Admin';

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: Home
    },
    {
        path: '/contact',
        name: 'contact',
        component: Contact
    },
    {
        path: '/login',
        name: 'login',
        component: Login
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: Dashboard
    },
    {
        path: '/admin',
        name: 'admin',
        component: Admin
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    const publicRoutes = [ '/', '/contact', '/login' ];

    if(publicRoutes.includes(to.path) && localStorage.getItem('token'))
    {
        return next('/dashboard')
    }

    if(!publicRoutes.includes(to.path) && !localStorage.getItem('token'))
    {
        return next('/login');
    }
    
    next();
})

export default router
