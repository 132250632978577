import userService from "../../services/user";
import router from '../../router';

const token = localStorage.getItem('token');
const user = JSON.parse(localStorage.getItem('user'));
const state = token ? { loggedIn: true, token, user } : { loggedIn: false, token: null, user: null };

export default {
    namespaced: true,
    state,
    actions: {
        async login({dispatch, commit},  {username, password })
        {
            try {
                let data = await userService.login(username, password);
                commit('loginSuccess', data);
                router.push('/dashboard');
            } catch (error) {
                commit('loginFailure');
                dispatch('alert/error', 'Hibás bejelentkező adatok', { root: true });
            }
        },
        async logout({ commit }) 
        {
            userService.logout();
            commit('logout');
            router.push('/');
        }
    },
    mutations: {
        loginSuccess(state, { token, user })
        {
            state.loggedIn = true;
            state.token = token;
            state.user = user;
        },
        loginFailure(state)
        {
            state.loggedIn = false;
            state.token = null;
            state.user = null;
        },
        logout(state)
        {
            state.loggedIn = false;
            state.token = null;
            state.user = null;
        }
    },
    getters: {
        name: state => {
            if(state.user != null)
            {
                return state.user.una;
            }

            return null;
        },
        isAdmin: state => {
            return state.user != null ? state.user.adm : false;
        }
    }
}