import { C2Client } from "@fusyoosoftwares/c2-client";
import { decode } from "jsonwebtoken";

const c2 = new C2Client('live', 'Digi-Signer');

export default {
    async login(username, password) {
        let token = await c2.login(username, password);
        token = token.token;

        localStorage.setItem('token', token);
        
        let cleanToken = token.replace(/\//g, '_').replace(/\+/g, '_');
        let user = decode(cleanToken);
        
        localStorage.setItem('user', JSON.stringify(user));

        return {token, user};
    },
    async logout() {
        // no logout action available for c2 client (yet)
        localStorage.removeItem('token');
        localStorage.removeItem('user');
    }
}