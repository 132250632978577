<template>
    <div>
        Feltöltés alatt...
    </div>
</template>

<script>
import PublicLayout from '@/components/layouts/Public'
export default {
    name: 'Home',
    created() {
        this.$emit('update:layout', PublicLayout)
    }
}
</script>

<style>

</style>
