import axios from "axios";

const axiosInstance = axios.create({
    headers: {
        Accept: 'application/json,text/plain'
    },
})

export default {
    namespaced: true,
    state: {
        companies: []
    },
    actions: {
        async fetchCompanies({ commit, dispatch }, {from, to})
        {
            axiosInstance.defaults.headers.Authorization = localStorage.getItem('token');
            try {
                let url = '/api/companies/summary';
                
                if(from)
                {
                    from = from.toISO()
                    url += `/${from}`;
                    if(to)
                    {
                        to = to.toISO();
                        url += `/${to}`
                    }
                }

                let companies = await axiosInstance.get(url);
                commit('setCompanies', companies.data);
            } catch (error) {
                console.log(error);
                dispatch('alert/error', 'Hiba történt az adatok lekérdezése során.', { root: true });
            }
        },
        async updateCompany( { dispatch }, company )
        {
            axiosInstance.defaults.headers.Authorization = localStorage.getItem('token');
            try {
                await axiosInstance.post(`/api/companies/${company.id}`, company, {

                })
            } catch (error) {
                dispatch('alert/error', 'Hiba történt a változtatások mentése során.', { root: true });
            }
        }
    },
    mutations: {
        setCompanies(state, companies)
        {
            state.companies = companies;
        }
    },
    getters: {
        companies: state => {
            return state.companies;
        }
    }
}